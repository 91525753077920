import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BookFiltersState } from '../../store/book-filters.state';
import { combineLatest, map, Subject, takeUntil } from 'rxjs';
import { AppService } from '../../services/app.service';
import { LanguageService } from '../../services/language.service';
import { ButtonComponent } from '../button/button.component';
import { LanguageState } from '../../store/language.state';
import { ComponentRef } from '@ionic/core';
import { BookFiltersModalPage } from './modals/book-filters/book-filters-modal.page';
import { ModalService } from '../../services/modal.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-book-filters-btn',
  templateUrl: './book-filters-btn.component.html',
  imports: [ButtonComponent],
})
export class BookFiltersBtnComponent implements OnInit, OnDestroy {
  constructor(
    private appService: AppService,
    private bookFiltersState: BookFiltersState,
    private modalService: ModalService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private ref: ChangeDetectorRef,
    private languageState: LanguageState,
  ) {}

  private destroyed$ = new Subject<void>();

  selectedLanguage$ = combineLatest([this.bookFiltersState.bookFilters$, this.appService.isMobile$, this.languageState.language$]).pipe(
    map(([bookFilters, isMobile]) => {
      if (bookFilters.selectedLanguage?.name) {
        return bookFilters.selectedLanguage?.name;
      } else {
        return isMobile ? '' : this.translateService.instant('PWA_homeLibrary_filters_language');
      }
    }),
  );

  selectedGrade$ = combineLatest([this.bookFiltersState.bookFilters$, this.appService.isMobile$, this.languageState.language$]).pipe(
    map(([bookFilters, isMobile]) => {
      if (bookFilters.selectedGrade?.name.default) {
        return this.languageService.translateBackendCopy(bookFilters.selectedGrade.name);
      } else {
        return isMobile ? '' : this.translateService.instant('PWA_homeLibrary_filters_age_allAges');
      }
    }),
  );

  private readonly mobileFiltersBreakPoints = {
    breakpoints: [0, 1],
    initialBreakpoint: 1,
  };

  ngOnInit(): void {
    this.bookFiltersState.bookFilters$.pipe(takeUntil(this.destroyed$)).subscribe(() => this.ref.detectChanges());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  async displayFilter(): Promise<void> {
    const cssClass = this.appService.isMobile$.value ? 'mobile-language-filter' : 'desktop-language-filter';
    const breakPoints = this.appService.isMobile$.value ? this.mobileFiltersBreakPoints : {};

    const ageFilterModal = await this.modalService.create({
      component: BookFiltersModalPage as ComponentRef,
      cssClass: 'wr-modal ' + cssClass,
      handle: false,
      ...breakPoints,
    });

    await ageFilterModal.present();
  }
}
