<div class="main-container">
  <form [formGroup]="searchForm" class="main-container">
    <div class="search-bar-container" [class.is-desktop-with-title]="(desktopTitle && !this.appService.isMobile$.value)">

      @if ((appService.isMobile$ | async) && !activeSearch) {
        <div class="search-mobile-left">
          <app-button data-cy="search-mobile-btn" [icon]="'assets/icon/search.svg'" [color]="'white'" (clicked)="focusSearch(true)" [shape]="'circle'" [size]="'small'" />
        </div>

      }

      @if((desktopTitle && !this.appService.isMobile$.value)) {
        <div class="page-title" data-cy="search-page-title" >{{ desktopTitle }}</div>
      }

      @if((appService.isMobile$ | async) === false || activeSearch ) {
        <div class="search-container"
             [class.mobile]="appService.isMobile$ | async"
             [class.desktop-not-active-with-title]="(appService.isMobile$ | async) === false && !activeSearch && desktopTitle"
        >
          <div class="search-input-container"
               [class.mobile-active]="(appService.isMobile$ | async) && activeSearch"
               [class.desktop-active]="(appService.isMobile$ | async) === false && activeSearch">
            <ion-input
              data-cy="search-input"
              (keyup.escape)="focusSearch(false)"
              formControlName="search"
              (ionFocus)="focusSearch(true)"
              class="search"
              [class.mobile-active]="(appService.isMobile$ | async) && activeSearch"
              [placeholder]="'PWA_mobile_mainHeader_searchField' | translate"
              #search>
              <img src="assets/icon/search.svg" slot="start" />
            </ion-input>
            @if (activeSearch) {
              <div data-cy="search-cancel" class="cancel" (click)="focusSearch(false)">{{ 'PWA_search_header_CTA_cancel' | translate }}</div>
            }
          </div>
          @if((appService.isMobile$ | async) === false) {
            <ng-container *ngTemplateOutlet="searchHistoryTemplate"></ng-container>
          }
        </div>
      }


      @if (!activeSearch) {
        <div class="app-buttons" [class.mobile]="appService.isMobile$ | async" [@fadeInOutH]>
          <ng-content select="[slot=buttons]" />
        </div>
      }
    </div>
  </form>


  @if (((appService.isMobile$ | async) && activeSearch)) {
    <ng-container *ngTemplateOutlet="searchHistoryTemplate"></ng-container>
  }

  @if (activeSearch) {
    <div class="results-container" data-cy="search-results">
      @if (isLoading) {
        <div class="spinner-container">
          <ion-spinner name="dots"></ion-spinner>
        </div>
      }

      @if (!isLoading) {
        @if (books?.length) {
          <div data-cy="search-result-books">
            <div class="title">{{ 'PWA_search_results_header' | translate }}</div>
            <app-book-grid [books]="books || []"></app-book-grid>
          </div>
        }

        @if (books?.length === 0) {
          <div class="title no-result" [@fadeInOut] data-cy="search-no-results">{{ 'PWA_search_NoResults_header' | translate }}</div>
        }

        <div class="popular-books" data-cy="search-no-results-popular-books" [class.mobile]="appService.isMobile$ | async" [class.hidden]="books && books.length > 0">
          <div class="title">{{ 'PWA_search_suggestion_checkPopular' | translate }}</div>
          <app-book-grid [books]="popularBooks"></app-book-grid>
        </div>
      }
    </div>
  }
</div>



<ng-template #searchHistoryTemplate>
  <div class="search-history-container" [class.mobile]="appService.isMobile$ | async">
    @if ((searchState.search$ | async)?.history?.length && activeSearch) {
      <div class="history" [@fadeInDelay] data-cy="search-history">
        <div class="history-header">
          <div class="history-title">{{ 'PWA_search_recentSearches_header' | translate }}</div>
          <div class="history-clear" data-cy="search-clear-history" (click)="clearSearchHistory()">
            {{ 'PWA_search_recentSearches_CTA_link_clear' | translate }}
          </div>
        </div>

        <div class="history-content">
          @for (search of (searchState.search$ | async)?.history; track $index) {
            <div class="history-word" [@fadeInOut] (click)="historySearch(search)" data-cy="search-history-word">{{ search }}</div>
          }
        </div>
      </div>
    }
  </div>
</ng-template>
